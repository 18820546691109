import { useQuery } from 'react-query';
import { EntityRuleInsight } from '@odaia/domain/src/types';
import { maptualApiInstance } from '../../../../../../request/config';

export const useEntityRuleInsights = ({ regionId, project, objectiveId }) =>
  useQuery({
    queryKey: ['entity-rule-insights', regionId, objectiveId],
    queryFn: () => getEntityRuleInsights(project, regionId, objectiveId),
    enabled: !!regionId && !!objectiveId,
  });

export const getEntityRuleInsights = (project, regionId, objectiveId) =>
  maptualApiInstance.get<Record<string, EntityRuleInsight>>(
    `core/product-lines/${project?.metadata?.productLineId}/regions/${regionId}/objectives/${objectiveId}/entities/insights`,
    {
      timeout: 20000,
    }
  );
