import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useQuery } from 'react-query';
import {
  addListCountsToMaptualList,
  addNewHcpsToListItems,
} from '../../../../utils/projectViewReducerUtils';
import { AppContext } from '../../appContext';
import { useMaptualLists } from '../../../fieldview/useMaptualLists';
import { useMaptualList } from '../../../fieldview/useMaptualList';
import {
  getDemoAccountStatus,
  userHasCallPlanAccess,
} from '../../../../request/config';
import { ROUTES, CUSTOM_LISTS } from '../../../../constants';
import { getEntityViewsConfig } from '../../../../request/entityOverviewRequests';
import useFeatureToggles from '../../../../hooks/useFeatureToggles';
import { useLatestTimestamp as useLatestCurationTimestamp } from '../pulse/context/hooks/useLatestTimestamp';
import { useUserCreatedLists } from '../../../fieldview/useUserCreatedLists';
import { useIsAuthorized } from '../../../../hooks/useIsAuthorized';

const UUID_LENGTH = 36;

const updateMaptualListWithUserCreatedProperties = (
  project,
  maptualList,
  newUserCreatedLists
) => {
  const projectProductLineId = project?.metadata?.productLineId;
  const maptualListItems = maptualList?.list?.items;

  if (!newUserCreatedLists || !newUserCreatedLists?.[projectProductLineId]) {
    return {
      ...maptualList,
      list: {
        ...maptualList?.list,
        items: maptualListItems,
      },
    };
  }

  const updatedHcps = addNewHcpsToListItems(
    maptualListItems,
    newUserCreatedLists[projectProductLineId]
  );

  const updatedMaptualList = {
    ...maptualList,
    list: {
      ...maptualList?.list,
      items: updatedHcps,
    },
  };

  const finalMaptualList = addListCountsToMaptualList(
    updatedMaptualList,
    newUserCreatedLists[projectProductLineId]
  );

  return finalMaptualList;
};

const censorMaptualListMetadata = (metadata) => {
  if (getDemoAccountStatus()) {
    return { ...metadata, listName: 'Territory' };
  }
  return metadata;
};

export const useFieldContext = () => {
  const { projectList, profile } = useContext(AppContext);
  const [navigationOpen, setNavigationOpen] = useState(false);

  const maptualListFilterPreferences =
    profile?.preference?.maptualListFilterPreferences || {};

  const territoriesIdLabelMap = useSelector(
    (state) => state.projectViewer.maptualList?.metadata?.childEntities
  );

  const [customListType, setCustomListType] = useState('');

  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const { userCreatedLists } = useUserCreatedLists({
    userId: profile?.userId,
  });

  const [project, setProject] = useState({});
  const [projectMaptualList, setProjectMaptualList] = useState({});

  const {
    maptualListHierarchy,
    maptualListFlat,
    maptualListObjectives,
    isMaptualListsLoading,
  } = useMaptualLists({
    projectId: project?.metadata?.projectId,
  });
  const [maptualListMetadata, setMaptualListMetadata] = useState({});
  const [regionHasCuratedList, setRegionHasCuratedList] = useState(null);
  const featureToggles = useFeatureToggles();
  const isAuthorized = useIsAuthorized();

  const navigateWithQueryParams = (url) => {
    history.push(`${url}${history.location.search}`);
  };

  const getTourId = (queryParamString) =>
    queryParamString.slice(queryParamString.indexOf('product_tour_id') + 16);

  const getViewFromParams = () => {
    const regionHasCallPlanAccess =
      userHasCallPlanAccess(profile, regionHasCuratedList) &&
      featureToggles(profile.userGroup, 'curatedList');
    const view = location.pathname.split('/').slice(6);
    if (view.length > 0) {
      return `/${view.join('/')}`;
    }
    // eslint-disable-next-line no-nested-ternary
    return regionHasCallPlanAccess
      ? ROUTES.CALL_PLAN
      : featureToggles(profile.userGroup, 'prioritizeMapView') &&
        featureToggles(profile.userGroup, 'mapView') &&
        isAuthorized(['view-map'])
      ? `${ROUTES.HCPS}${ROUTES.MAP}`
      : ROUTES.HCPS;
  };

  const {
    isLoading: isLatestCurationTimestampLoading,
    data: latestCurationTimestamp,
  } = useLatestCurationTimestamp({
    productLineId: project?.metadata?.productLineId,
    regionId: maptualListMetadata?.maptualListId,
    currentList: true,
    enabled:
      !!project?.metadata?.productLineId &&
      !!maptualListMetadata?.maptualListId &&
      featureToggles(profile.userGroup, 'curatedList'),
  });

  useEffect(() => {
    if (
      !!project?.metadata?.productLineId &&
      !!maptualListMetadata?.maptualListId
    ) {
      setRegionHasCuratedList(
        !!latestCurationTimestamp && typeof latestCurationTimestamp === 'string'
      );
    }
  }, [latestCurationTimestamp]);

  const updateRoute = () => {
    if (params && params[0] === 'projects') {
      let route = `${ROUTES.FIELD}${ROUTES.PROJECTS}`;
      if (
        maptualListMetadata?.maptualListId &&
        params?.maptualListId !== maptualListMetadata.maptualListId
      ) {
        route = `${ROUTES.FIELD}${ROUTES.PROJECTS}/${project.metadata.projectId}/maptualListId/${maptualListMetadata.maptualListId}`;

        // Intercom Tours fallback - start tour when we see the tour link if tour hasnt loaded
        const queryParamString = history.location.search;
        if (queryParamString.includes('product_tour_id')) {
          let retryTourCount = 8;
          const tourStarter = setInterval(() => {
            if (
              !document.querySelector('.intercom-tour-frame') &&
              retryTourCount > 0
            ) {
              window.Intercom('startTour', getTourId(queryParamString));
              retryTourCount++;
            } else {
              clearInterval(tourStarter);
            }
          }, 2000);
          history.push(route);
          return;
        }
        navigateWithQueryParams(route);
      }
      if (
        project?.metadata?.projectId &&
        maptualListMetadata?.maptualListId &&
        !isLatestCurationTimestampLoading &&
        regionHasCuratedList !== undefined
      ) {
        const landingRoute = getViewFromParams();
        route = `${ROUTES.FIELD}${ROUTES.PROJECTS}/${project.metadata.projectId}/maptualListId/${maptualListMetadata.maptualListId}${landingRoute}`;
        navigateWithQueryParams(route);
      }
    }
  };

  const getSelectedProjectId = () => {
    if (params?.projectId) return params.projectId;

    if (profile?.preference?.lastProjectMetadata?.projectId)
      return profile?.preference?.lastProjectMetadata?.projectId;

    if (projectList?.length && projectList[0].projectId)
      return projectList[0].projectId;

    return null;
  };

  const getSelectedRegionId = () => {
    if (
      params?.maptualListId &&
      Object.keys(maptualListFlat).includes(params?.maptualListId)
    )
      return params.maptualListId;

    if (
      profile?.preference?.lastMaptualListMetadata?.maptualListId &&
      Object.keys(maptualListFlat).includes(
        profile?.preference?.lastMaptualListMetadata?.maptualListId
      )
    )
      return profile?.preference?.lastMaptualListMetadata?.maptualListId;

    if (profile?.role?.territoryIds && profile?.role?.territoryIds.length > 0) {
      const firstAssignedTerritoryName = profile?.role?.territoryIds[0];
      const userTerritoryMetadata = Object.values(maptualListFlat).find(
        (region) => firstAssignedTerritoryName === region.listName
      );
      if (userTerritoryMetadata) {
        return userTerritoryMetadata.maptualListId;
      }
    }
    if (
      Object.keys(maptualListFlat || []).length &&
      Object.values(maptualListFlat)[0].maptualListId
    )
      return Object.values(maptualListFlat)[0].maptualListId;

    return null;
  };

  const [objective, setObjective] = useState({});

  useEffect(() => {
    // TODO remove temp check after migrate routes other than projects
    if (params[0] !== 'projects' || !projectList?.length) return;
    const selectedProjectId = getSelectedProjectId();
    const projectFromList = projectList.find(
      (proj) => proj.projectId === selectedProjectId
    );

    setObjective({});

    if (!projectFromList) {
      setProject({ metadata: projectList[0] });
      return;
    }

    setProject({ metadata: projectFromList });
    setMaptualListMetadata({});
  }, [params?.projectId, projectList]);

  useEffect(() => {
    if (
      params[0] !== 'projects' ||
      (!params?.maptualListId && maptualListMetadata?.maptualListId)
    )
      return;
    const selectedRegionId = getSelectedRegionId();
    if (!selectedRegionId) return;

    setMaptualListMetadata(
      censorMaptualListMetadata(maptualListFlat[selectedRegionId])
    );
  }, [params?.maptualListId, maptualListFlat]);

  useEffect(() => {
    if (
      !params?.customListType &&
      Object.keys(CUSTOM_LISTS).includes(params?.customListType)
    )
      return;

    setCustomListType(params?.customListType);
  }, [params?.customListType]);

  useEffect(() => {
    if (
      !params?.maptualListId &&
      regionHasCuratedList !== undefined &&
      regionHasCuratedList !== null
    ) {
      setRegionHasCuratedList(undefined);
    }
  }, [params?.maptualListId]);

  useEffect(() => {
    if (params[0] !== 'projects') return;
    updateRoute();
  }, [maptualListMetadata?.maptualListId, params[0], regionHasCuratedList]);

  const marketId = project?.metadata?.marketId;
  const { data: entityViews = [] } = useQuery(
    `entityViews-${marketId}`,
    async () => {
      if (marketId) {
        const response = await getEntityViewsConfig(marketId);
        return response?.data.views;
      }

      return [];
    },
    {
      enabled: marketId && marketId.length === UUID_LENGTH,
    }
  );

  const {
    maptualList,
    isMaptualListLoading,
    refetchMaptualList,
    updateMaptualList,
  } = useMaptualList({
    project,
    maptualListId: maptualListMetadata?.maptualListId,
    objectiveId: objective?.id,
    userCreatedLists,
    customListType,
  });

  useEffect(() => {
    if (
      maptualList?.list?.items?.length > 0 &&
      !maptualList?.list?.items?.length[0]?.objectiveScores
    ) {
      setProjectMaptualList(maptualList);
    }
  }, [maptualList]);

  const updateMaptualListUserCreatedProperties = (newUserList) => {
    updateMaptualList(
      updateMaptualListWithUserCreatedProperties(
        project,
        maptualList,
        newUserList
      )
    );
  };

  const segmentList = maptualList?.segments;

  return {
    project,
    maptualListHierarchy,
    maptualListFlat,
    maptualListMetadata,
    isMaptualListsLoading,
    navigationOpen,
    setNavigationOpen,
    territoriesIdLabelMap,
    isMaptualListLoading,
    refetchMaptualList,
    maptualList,
    projectMaptualList,
    segmentList,
    updateMaptualListUserCreatedProperties,
    maptualListFilterPreferences,
    maptualListObjectives,
    objective,
    setObjective,
    customListType,
    entityViews,
    isLatestCurationTimestampLoading,
    regionHasCuratedList,
  };
};
