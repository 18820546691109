import { useContext, useEffect, useState } from 'react';
import { styled, Skeleton, Typography, Box } from '@mui/material';
import { PieChartOutline, QueryStatsOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import InsightsAreaChart from './insightsAreaChart';
import PrimaryContentBox from '../../generic/primaryContentBox';
import {
  getIcon,
  transformValue,
  getColorState,
  COLOR_STATES,
} from '../trendValues';
import {
  RecentLabel,
  PredictedLabel,
  Title,
  Subtitle,
  Icon,
  MetricDateLabel,
} from './insightsGeneric';
import { AppContext } from '../../../containers/application/appContext';

const METRIC_DEFINITIONS = {
  nbrx: 'Denotes the first time, ever, that a patient has been placed on this particular drug.',
  nrx: 'Any time a patient receives a new prescription (new paper) after their first time being placed on that drug.',
  trx: 'Includes all NBRx and NRx prescriptions as well as any refills/repeats that a patient uses.',
};

const TrendWrapper = styled('div')(() => ({
  display: 'flex',
  width: 'fit-content',
  padding: '2px 5px',
  borderRadius: 3,
  marginTop: '4px',
  gridGap: '5px',
}));

const getColor = ({ type, status, value }) => {
  const { themeColors } = useTheme();
  const INCR_PAIR = [
    themeColors.insightsPositiveText,
    themeColors.insightsPositiveBackground,
  ];
  const DECR_PAIR = [
    themeColors.insightsNegativeText,
    themeColors.insightsNegativeBackground,
  ];
  const NETR_PAIR = [
    themeColors.buttonSecondaryContentColour,
    themeColors.buttonSecondaryBackgroundColour,
  ];

  const colorMap = {
    [COLOR_STATES.NEUTRAL]: NETR_PAIR,
    [COLOR_STATES.POSITIVE]: INCR_PAIR,
    [COLOR_STATES.NEGATIVE]: DECR_PAIR,
  };
  const colorState = getColorState({ type, status, value });
  if (!colorState) {
    return NETR_PAIR;
  }
  return colorMap[colorState];
};
const TrendBadge = ({ trendDict, useZero = false, collapsed = false }) => {
  const checkNull = true;
  const { type, status, value } = trendDict;
  const [color, backgroundColor] = getColor({ type, status, value });
  return (value !== undefined || status) && type ? (
    <TrendWrapper
      data-testid="trend-value-container"
      style={{ backgroundColor, color }}
    >
      {getIcon({ type, status, value, useZero, collapsed, checkNull })}
      <Typography
        noWrap
        align="left"
        variant="subtitle1"
        style={{ fontWeight: 500 }}
      >
        {transformValue({ type, status, useZero, value })}
      </Typography>
    </TrendWrapper>
  ) : null;
};

const CardRow = styled(Box)(() => ({
  display: 'flex',
  gridGap: '16px',
  marginBottom: '16px',
}));

const CardWrapper = styled(PrimaryContentBox)(({ theme: { themeColors } }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  justifyContent: 'start',
  color: themeColors.primaryMaptualListFilteringColor,
  backgroundColor: themeColors.contentCardBackgroundColor,
}));

const CardContent = styled(Box)(() => ({
  padding: 20,
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  gridGap: '8px',
  flexGrow: 1,
}));

const CardBody = styled(Box)(() => ({
  display: 'flex',
  gridGap: '32px',
  marginTop: '16px',
}));

const CardTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.secondaryTextColor,
}));

const CardIcon = styled(Icon)(({ theme: { themeColors } }) => ({
  color: themeColors.anchorIconsGeneralSecondary,
  backgroundColor: themeColors.anchorIconsGeneralPrimary,
}));

export const CardVisual = ({ visual, isPercentage }) => {
  const primary = visual.secondary.status < 0 ? null : visual.primary;
  return (
    <Box>
      <Title variant="h2" style={{ lineHeight: 1 }}>
        {primary && isPercentage
          ? `${Math.round(primary * 100)}%`
          : primary || '--'}
      </Title>

      <TrendBadge trendDict={visual.secondary} />

      {visual?.title === 'RECENT' ? (
        <RecentLabel data-testid="recent-label" />
      ) : null}

      {visual?.title === 'PRED' ? (
        <PredictedLabel data-testid="predicted-label" />
      ) : null}

      {visual && visual.metricDate && (
        <MetricDateLabel data-testid="metric-date-label" variant="subtitle2">
          {visual.metricDate.toUpperCase()}
        </MetricDateLabel>
      )}
    </Box>
  );
};

const determineUnit = () => {
  const { profile } = useContext(AppContext);
  if (profile?.userGroup?.includes('gskussbu')) {
    return 'R12M';
  }
  return 'MAT';
};

const CardDescription = ({ targetPeriod, type }) => {
  if (type === 'script') {
    const unit = determineUnit();

    return (
      <Typography variant="body2">
        Their recent {unit} and predicted {unit} script in{' '}
        <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
          {targetPeriod} months
        </span>
        .
      </Typography>
    );
  }
  return (
    <Typography variant="body2">
      Their recent annual market share and predicted annual share in{' '}
      <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>
        {targetPeriod} months
      </span>
      .
    </Typography>
  );
};

export const formatDefinition = (type, metric) => {
  if (!metric) {
    return '';
  }
  if (type === 'script') {
    return METRIC_DEFINITIONS[metric];
  }
  const formatMetric = `${metric.slice(0, -1).toUpperCase()}${metric.slice(
    -1
  )}`;
  return `Market defined by your business objectives using ${formatMetric}.`;
};

export const getHistorical = (increase, current) => {
  if (!increase || !current) {
    return current;
  }
  const multiplier = 1 + Math.round((increase + Number.EPSILON) * 100) / 100;
  const original = current / multiplier;
  return Math.round(original);
};

export const formatCardToSeries = (card, percentage) => {
  let historicalValue = null;
  let recentValue = null;
  let predictedValue = null;

  card?.visuals?.forEach((visual) => {
    if (visual.primary) {
      if (visual.title === 'RECENT') {
        recentValue = Math.round(visual.primary * (percentage ? 100 : 1));
        historicalValue = getHistorical(visual.secondary.value, recentValue);
      }
      if (visual.title === 'PRED') {
        if (visual.secondary.status < 0) {
          predictedValue = null;
        } else {
          predictedValue = Math.round(visual.primary * (percentage ? 100 : 1));
        }
      }
    }
  });

  const series = {
    name: '',
    data: [historicalValue, recentValue, predictedValue],
    type: 'area',
  };

  return series;
};

const Card = ({ cardData, icon, title, type, isPercentage = false }) => (
  <CardWrapper className={`intercom-field-${type}-insights-card`}>
    <CardContent>
      <CardIcon>{icon}</CardIcon>
      <CardTitle variant="title3">{title}</CardTitle>
      {cardData.targetPeriod ? (
        <CardDescription type={type} targetPeriod={cardData.targetPeriod} />
      ) : null}
      <CardBody>
        {cardData && cardData?.visuals?.length > 0 ? (
          <>
            <CardVisual
              visual={cardData.visuals[0]}
              isPercentage={isPercentage}
            />
            <CardVisual
              visual={cardData.visuals[1]}
              isPercentage={isPercentage}
            />
          </>
        ) : null}
        <div>
          <Subtitle variant="h6">{cardData.title}</Subtitle>
          <Typography variant="body2">
            {formatDefinition(type, cardData.metric)}
          </Typography>
        </div>
      </CardBody>
    </CardContent>

    <InsightsAreaChart
      series={formatCardToSeries(cardData, isPercentage)}
      isPercentage={isPercentage}
    />
  </CardWrapper>
);

const SkeletonLoadingBar = styled(Skeleton)(
  ({ theme: { themeColors, spacing } }) => ({
    backgroundColor: themeColors.surfaceEmpty,
    transform: 'scale(1)',
    marginBottom: spacing(2),
    height: 500,
    width: '100%',
  })
);

const InsightCardsSkeletonRow = () => (
  <CardRow>
    <SkeletonLoadingBar />
    <SkeletonLoadingBar />
  </CardRow>
);

export default function InsightsCardRow({ objectiveCards }) {
  const [shareData, setShareData] = useState({});
  const [scriptData, setScriptData] = useState({});

  useEffect(() => {
    if (objectiveCards && objectiveCards.length > 0) {
      const scriptCard =
        objectiveCards?.find(
          ({ cardType, productGroup }) =>
            cardType === 'script' && productGroup === 'target'
        ) ?? {};

      const shareCard =
        objectiveCards?.find(({ cardType }) => cardType === 'share') ?? {};

      setScriptData(scriptCard);
      setShareData(shareCard);
    }

    return () => {
      setScriptData({});
      setShareData({});
    };
  }, [objectiveCards]);

  const insightsLoading = !objectiveCards || objectiveCards?.length < 1;

  return insightsLoading ? (
    <InsightCardsSkeletonRow />
  ) : (
    <CardRow>
      <Card
        cardData={scriptData}
        icon={<QueryStatsOutlined />}
        title="Scripts Volume"
        type="script"
      />
      <Card
        cardData={shareData}
        icon={<PieChartOutline />}
        title="Market Share"
        type="share"
        isPercentage
      />
    </CardRow>
  );
}
