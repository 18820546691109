import { useEffect, useMemo, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Link, styled } from '@mui/material';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import { batch } from 'react-redux';
import EmptyView from '../emptyview/emptyView';
import DownloadButton from '../input/downloadButton';
import { trackProjectSelection } from '../../trackers/appEventTracker';
import ProjectTabContainer from './projectTabContainer';
import ProjectSearchResultsContainer from '../../containers/headofficeview/projectSearchResultsContainer';
import { TAB_PATHS } from './constants';
import { ROUTES } from '../../constants';
import { ObjectiveContext } from './contexts/objectiveContext';
import useFeatureToggles from '../../hooks/useFeatureToggles';

const FloatingSearchPane = styled('div')({
  position: 'absolute',
  background: '#303030',
  top: 100,
  bottom: 0,
  left: 65,
  right: 0,
  zIndex: 900,
});

const useStyles = makeStyles(({ themeColors }) => ({
  root: {
    width: '100%',
    display: 'flex',
    height: 'calc(100% - 130px);',
    marginTop: '2vh',
  },
  iconButtonStyle: {
    size: 'medium',
    color: 'inherit',
    backgroundColor: themeColors.inputBackground,
  },
  iconStyle: {
    height: '2rem',
    width: '2rem',
    margin: 0,
  },
  iconLinkContainer: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  linkTextStyle: {
    fontWeight: 600,
    fontSize: 20.25,
  },
  linkStyle: {
    padding: 0,
    margin: 0,
    backgroundColor: '#00000000',
    color: 'textSecondary',
  },
}));

const renderSearchResults = () => (
  <FloatingSearchPane>
    <ProjectSearchResultsContainer />
  </FloatingSearchPane>
);

export function HeadOfficeProjectItem({
  match: { params, url: basePathUrl },
  profile,
  returnMatch,
  getProject,
  getSegments,
  getDashboardStructure,
  getDashboardSubUrl,
  getAnalysis,
  setProjectItem,
  setInjectableComponents,
  setCustomCrumb,
  setRegionSelection,
  setSegmentSelection,
  setTabView,
  viewer,
  isSearchActive,
  location: { search },
}) {
  const classes = useStyles();
  const routeHistory = useHistory();
  const { themeColors } = useTheme();
  const {
    isBusy,
    isProjectItemBusy,
    isProjectSegmentsBusy,
    projectItem,
    selectedTab,
  } = viewer;
  const [selectedObjective, setSelectedObjective] = useState(null);
  const [isInTab, setIsInTab] = useState(TAB_PATHS.TABLE);
  const featureToggles = useFeatureToggles();

  const isLoading = isBusy || isProjectItemBusy || isProjectSegmentsBusy;
  const newGraphsEnabled = featureToggles(
    profile.userGroup,
    'showNewSphereGraphs'
  );
  const regionKey = newGraphsEnabled ? 'regionId' : 'maptualListId';
  const segmentKey = newGraphsEnabled ? 'sfSegmentId' : 'segmentId';

  const displayProjectText = () => {
    if (isLoading) return 'Loading Project ...';

    return projectItem
      ? projectItem?.metadata?.projectName || 'Unnamed Project'
      : 'Project Not Found';
  };

  const projectIdExistsInProfileProjects =
    profile?.projects?.some(
      (project) => project.projectId === params?.projectId
    ) || profile?.role?.type === 'ADMIN';

  useEffect(() => {
    if (
      params?.projectId &&
      (!projectItem || projectItem?.projectId !== params.projectId) &&
      projectIdExistsInProfileProjects
    ) {
      trackProjectSelection(params.projectId);
      setProjectItem(null);
      getProject(params.projectId);

      getSegments(params.projectId).then((actionReturn) => {
        const { regions, segments } = actionReturn.action.payload;
        const selectedRegion = regions[0];
        const selectedSegmentId = segments[0]?.[segmentKey];
        const defaultTabView = TAB_PATHS.TABLE;

        batch(() => {
          setRegionSelection(selectedRegion);
          setSegmentSelection(selectedSegmentId);
          setTabView(defaultTabView);
          setIsInTab(defaultTabView);
        });

        routeHistory.push(
          `${basePathUrl}?${regionKey}=${selectedRegion?.[regionKey]}&segmentId=${selectedSegmentId}&tab=${defaultTabView}`
        );
      });
    }
    if (params.externalAnalysisId) {
      const searchParams = new URLSearchParams(search);
      getAnalysis(params.externalAnalysisId, searchParams.get('userId'));
    }
  }, []);

  useEffect(() => {
    const closeControl = (
      <IconButton
        onClick={() => {
          routeHistory.push(returnMatch.path);
        }}
        className={classes.iconButtonStyle}
        style={{ marginLeft: '2rem' }}
        size="large"
      >
        <CloseIcon className={classes.iconStyle} />
      </IconButton>
    );
    setCustomCrumb({
      component: (
        <Link
          color="textSecondary"
          className={classes.linkStyle}
          component="button"
          style={{ marginRight: '0.8rem' }}
          disabled
          underline="hover"
        >
          <div className={classes.iconLinkContainer}>
            <Typography className={classes.linkTextStyle}>
              {displayProjectText()}
            </Typography>
          </div>
        </Link>
      ),
      action: null,
    });
    const re = /gskus\w*\d*/;

    if (!isLoading && projectItem) {
      setInjectableComponents([
        re.exec(profile?.userGroup) ? null : (
          <DownloadButton
            key="download-project"
            linkUrl={projectItem.metadata?.snapshotUrl}
            buttonTitle="Download Project"
            style={{
              backgroundColor: themeColors.primaryColorDark,
            }}
          />
        ),
        closeControl,
      ]);
    } else {
      setInjectableComponents([closeControl]);
    }
  }, [isLoading, projectItem, profile, viewer.selectedRegion?.maptualListId]);

  const contextValue = useMemo(
    () => ({
      selectedObjective,
      setSelectedObjective,
      selectedRegion: viewer.selectedRegion,
      regionKey,
      segmentKey,
      setIsInTab,
      isInTab,
    }),
    [
      selectedObjective,
      setSelectedObjective,
      viewer.selectedRegion,
      regionKey,
      segmentKey,
      setIsInTab,
      isInTab,
    ]
  );

  return (
    <div className={classes.root}>
      {isSearchActive && renderSearchResults()}
      {!isLoading && projectItem ? (
        <ObjectiveContext.Provider value={contextValue}>
          <ProjectTabContainer
            getDashboardStructure={getDashboardStructure}
            getDashboardSubUrl={getDashboardSubUrl}
            projectId={params.projectId}
            selectedTab={selectedTab}
            setTabView={setTabView}
            viewer={viewer}
            setRegionSelection={setRegionSelection}
            setSegmentSelection={setSegmentSelection}
            selected
          />
        </ObjectiveContext.Provider>
      ) : (
        <div style={{ width: '100%', height: '100%' }}>
          <EmptyView
            isLoading={isLoading}
            isUnavailableProject
            refreshAction={() =>
              routeHistory.push(`${ROUTES.SPHERE}${ROUTES.PROJECTS}`)
            }
          />
        </div>
      )}
    </div>
  );
}

HeadOfficeProjectItem.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.string,
      maptualListId: PropTypes.string,
      externalAnalysisId: PropTypes.string,
    }),
    url: PropTypes.string,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  returnMatch: PropTypes.shape({
    path: PropTypes.string,
  }),
  getProject: PropTypes.func,
  getSegments: PropTypes.func,
  getDashboardStructure: PropTypes.func,
  getDashboardSubUrl: PropTypes.func,
  getAnalysis: PropTypes.func,
  setProjectItem: PropTypes.func,
  setInjectableComponents: PropTypes.func,
  setCustomCrumb: PropTypes.func,
  isSearchActive: PropTypes.bool,
  viewer: PropTypes.shape({
    isBusy: PropTypes.bool,
    selectedSegmentId: PropTypes.string,
    selectedRegion: PropTypes.shape({
      maptualListId: PropTypes.string,
      regionId: PropTypes.string,
    }),
    projectItem: PropTypes.shape({
      metadata: PropTypes.shape({
        projectName: PropTypes.string,
        snapshotUrl: PropTypes.string,
      }),
    }),
    isAnalysisExpanded: PropTypes.bool,
  }),
};
