import { Box, IconButton, Typography, styled } from "@mui/material";
import TimelineIcon from "@mui/icons-material/Timeline";
import RouteIcon from "@mui/icons-material/Route";
import { formatLastContactedDate } from "@odaia/domain/src";
import { PowerScore } from "../../powerScore";

const StyledMapListItem = styled("div")(
  ({ theme: { themeColors }, active }) => ({
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: 24,
    borderBottom: `1px solid ${themeColors.borderPrimaryColor}`,
    textAlign: "left",
    cursor: "pointer",
    backgroundColor: active
      ? themeColors.HCPListItemBackgroundActive
      : themeColors.HCPListItemBackground,
    "&:hover": {
      backgroundColor: active
        ? themeColors.HCPListItemBackgroundActive
        : themeColors.HCPListItemBackgroundHover,
    },
    "&:first-of-type": {
      borderTop: `1px solid ${themeColors.borderPrimaryColor}`,
    },
  })
);

const StyledMaptualListDetails = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
}));

const StyledMaptualListDetailsText = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
}));

const StyledMapListItemTitle = styled("div")(({ theme: { themeColors } }) => ({
  fontSize: 16,
  fontWeight: 500,
  color: themeColors.primaryTextColor,
}));

const StyledMapListItemSubTitle = styled("div")(
  ({ theme: { themeColors } }) => ({
    fontSize: 14,
    fontWeight: 400,
    color: themeColors.tertiaryColor,
  })
);

const StyledMapListItemPowerscore = styled("div")(() => ({
  fontWeight: 600,
  color: "red",
  marginLeft: "auto",
  alignSelf: "center",
}));

const ItemBody = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: 8,
}));

const BodyText = styled(Typography)(({ theme: { themeColors } }) => ({
  color: themeColors.primaryTextColor,
  fontSize: "14px",
  fontWeight: 500,
  lineheight: "120%",
  textTransform: "capitalize",
}));

const InsightIconBackground = styled("div")(({ theme: { themeColors } }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "24px",
  height: "24px",
  minWidth: "24px",
  minHeight: "24px",
  backgroundColor: themeColors.insightsIconBackground,
  borderRadius: "50%",
}));

const InsightIcon = styled(TimelineIcon)(({ theme: { themeColors } }) => ({
  width: "16px",
  height: "16px",
  color: themeColors.insightsIcon,
  opacity: 1,
}));

const StyledMapListItemActions = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row-reverse",
}));

const StyledIconButton = styled(IconButton)(
  ({ active, theme: { themeColors } }) => ({
    backgroundColor: active ? themeColors.buttonActiveBackgroundColor : "none",
    border: active
      ? `1px solid ${themeColors.buttonActiveBorderColor}`
      : "1px solid transparent",
    borderRadius: "5px",
  })
);

export const MapListItem = ({
  item,
  onSelectMaptualListItem,
  selectedMaptualListItem,
  selectedObjectiveIndex,
  routeEntities,
  setRoute,
  setIsRouteLoading,
}) => {
  const isInRoute =
    routeEntities.length > 0 &&
    routeEntities.some((e) => e.snowflakeEntityId === item.snowflakeEntityId);

  const handleRouteClick = (e) => {
    setIsRouteLoading(true);
    if (isInRoute) {
      setRoute(
        routeEntities.filter(
          (e) => e.snowflakeEntityId !== item.snowflakeEntityId
        )
      );
    } else {
      setRoute([...routeEntities, item]);
    }
    e.stopPropagation();
  };

  return (
    <StyledMapListItem
      id={`map-list-item-${item.snowflakeEntityId}`}
      onClick={() => {
        onSelectMaptualListItem(item);
      }}
      active={
        selectedMaptualListItem?.snowflakeEntityId === item.snowflakeEntityId
      }
      key={item.snowflakeEntityId}
    >
      <StyledMaptualListDetails>
        <StyledMaptualListDetailsText>
          <StyledMapListItemTitle>{item.primaryText}</StyledMapListItemTitle>
          <StyledMapListItemSubTitle>
            {formatLastContactedDate(item.secondaryText?.split("-")[0]?.trim())}
          </StyledMapListItemSubTitle>
        </StyledMaptualListDetailsText>
        <StyledMapListItemPowerscore>
          <PowerScore
            score={item.objectiveScores[selectedObjectiveIndex]}
            objectiveScoreList={item.objectiveScores
              .slice(0, selectedObjectiveIndex)
              .concat(item.objectiveScores.slice(selectedObjectiveIndex + 1))}
            isSelected={false}
          />
        </StyledMapListItemPowerscore>
      </StyledMaptualListDetails>
      {item.insight.title && (
        <ItemBody>
          <InsightIconBackground>
            <InsightIcon />
          </InsightIconBackground>
          <BodyText>{item.insight.title.replace(/^#*\s/, "")}</BodyText>
        </ItemBody>
      )}
      <StyledMapListItemActions>
        <StyledIconButton active={isInRoute} onClick={handleRouteClick}>
          <RouteIcon sx={{ fontSize: 16 }} />
        </StyledIconButton>
      </StyledMapListItemActions>
    </StyledMapListItem>
  );
};
