import { useQuery } from 'react-query';
import { fieldApi } from '../../request/maptualApiClient';

export const useEntity = ({ entityId }) => {
  const { data: entity, isLoading: isEntityBusy } = useQuery({
    queryKey: ['entity-data', entityId],
    queryFn: async () => {
      const response = await fieldApi.getEntityData({
        entityId,
      });
      return response.data;
    },
    enabled: !!entityId,
  });

  return {
    entity,
    isEntityBusy,
  };
};
