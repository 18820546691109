import { useQuery } from 'react-query';
import {
  getEntitiesPrecallInsights,
  getEntityPrecallInsights,
} from '../../../../../../request/projectRequests';
import { EntityType } from '../../../../../../clients/maptualAPI';

export const useEntityPrecallInsights = ({
  maptualListMetadata,
  project,
  objectiveId,
  entityId = null,
}) =>
  useQuery({
    queryKey: [
      'map-entity-insights',
      maptualListMetadata.sfMaptualListId,
      objectiveId,
      entityId,
    ],
    queryFn: async () => {
      try {
        if (!entityId) {
          const response = await getEntitiesPrecallInsights({
            projectId: project.metadata.projectId,
            objectiveId,
            regionId: maptualListMetadata.sfMaptualListId,
            entityType: EntityType.Hcp,
          });
          return response.data;
        }

        const response = await getEntityPrecallInsights({
          entityId,
          projectId: project.metadata.projectId,
          objectiveId,
          regionId: maptualListMetadata.sfMaptualListId,
        });

        return response.data || [];
      } catch {
        return [];
      }
    },
    enabled:
      !!maptualListMetadata.maptualListId &&
      !!objectiveId &&
      !!project.metadata.projectId,
  });
