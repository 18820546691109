import { useContext, useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Fade from '@mui/material/Fade';
import EmptyView from '../emptyview/emptyView';
import { EntityMaptualInsightsView } from './entityMaptualInsights';
import { FieldContext } from '../../containers/application/appViews/field/fieldContext';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 2,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function HCPEntityPage({
  entityId,
  snowflakeEntityId,
  insights,
  getInsights,
  isMaptualListBusy,
  displayUserCreatedListsButton = false,
  standaloneParams = null,
}) {
  const classes = useStyles();
  const fieldContext = useContext(FieldContext);

  const [currentObjectiveId, setCurrentObjectiveId] = useState(null);
  const [projectId, setProjectId] = useState(
    fieldContext?.project?.metadata?.projectId
  );
  const [isNewProjectId, setIsNewProjectId] = useState(false);

  useEffect(() => {
    if (
      fieldContext?.objective &&
      Object.keys(fieldContext?.objective).length > 0 &&
      fieldContext.objective.id
    ) {
      setCurrentObjectiveId(fieldContext.objective.id);
    }
  }, [
    fieldContext?.objective,
    fieldContext?.maptualListMetadata?.maptualListId,
  ]);

  useEffect(() => {
    if (
      standaloneParams?.projectId ||
      fieldContext?.project?.metadata?.projectId
    ) {
      const oldProjectId = projectId;
      const newProjectId =
        standaloneParams?.projectId ||
        fieldContext?.project?.metadata?.projectId;

      if (oldProjectId !== newProjectId) {
        setProjectId(newProjectId);
        setIsNewProjectId(true);
      } else {
        setIsNewProjectId(false);
      }
    }
  }, [standaloneParams, fieldContext?.project?.metadata?.projectId]);

  return (
    <Fade in timeout={600} key={entityId || '0'}>
      <div className={classes.root} data-testid="test-hcp-entity-page">
        {!isMaptualListBusy ? (
          <EntityMaptualInsightsView
            snowflakeEntityId={snowflakeEntityId}
            insights={insights}
            displayUserCreatedListsButton={displayUserCreatedListsButton}
            standaloneParams={standaloneParams}
            projectId={projectId}
            isNewProjectId={isNewProjectId}
            setIsNewProjectId={setIsNewProjectId}
            currentObjectiveId={currentObjectiveId}
            setCurrentObjectiveId={setCurrentObjectiveId}
          />
        ) : (
          <EmptyView
            isLoading={isMaptualListBusy}
            loadingTitle={
              (isMaptualListBusy && 'Waiting for the List to Load ...') ||
              'Loading HCP ...'
            }
            refreshAction={() => {
              try {
                getInsights();
              } catch (error) {
                // eslint-disable-next-line no-console
                console.error('[ERROR] Reload HCP Page Error ', error);
              }
            }}
          />
        )}
      </div>
    </Fade>
  );
}
