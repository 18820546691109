import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getTableResponse } from '../request/projectRequests';
import useMaptualContext from './useMaptualContext';
import { ObjectiveContext } from '../components/headoffice/contexts/objectiveContext';
import { createColumns } from '../components/headoffice/tableview/dynamicColumns';
import { censorHCPTableData } from '../modules/demoCensor';
import { AppContext } from '../containers/application/appContext';
import useFeatureToggles from './useFeatureToggles';

const validateParams = (params) => Object.values(params).every(Boolean);

export const useHCPTableData = () => {
  const { projectId } = useMaptualContext();
  const { projectList, profile } = useContext(AppContext);
  const featureToggles = useFeatureToggles();
  const productLineId = projectList.find(
    (proj) => proj.projectId === projectId
  )?.productLineId;
  const selectedSegmentId = useSelector(
    (state) => state?.projectViewer?.selectedSegmentId
  );
  const { selectedRegion, selectedObjective } = useContext(ObjectiveContext);

  let regionId = selectedRegion?.maptualListId;
  if (featureToggles(profile.userGroup, 'showNewSphereGraphs')) {
    regionId = selectedRegion?.regionId;
  }

  const params = {
    productLineId,
    projectId,
    segmentId: selectedSegmentId,
    maptualListId: regionId,
    objectiveId: selectedObjective?.id,
    cacheString: 'table',
  };

  const queryCacheKey = Object.values(params);
  return useQuery(
    queryCacheKey,
    async () => {
      let response = await getTableResponse(params);
      response = censorHCPTableData(response.data);

      const rows = response.rows.map((row) => {
        const lastContacted = row?.lastContacted
          ? moment(row?.lastContacted).format('YYYY-MM-DD')
          : null;
        return { ...row, lastContacted };
      });

      const columns = createColumns(response.columnConfig);

      return {
        rows,
        columns,
      };
    },
    {
      enabled: validateParams({
        ...params,
      }),
    }
  );
};
