import { BrowserRouter as Router, Redirect } from 'react-router-dom';
import { SentryRoute } from '../../../../sentryRoute';
import { ROUTES } from '../../../../constants';
import LoginHandler from '../../loginHandler';
import { Navigation } from './navigation/Navigation';
import { PulseWrapper } from './PulseWrapper';
import { SalesReps } from './views/salesReps/salesReps';
import { Rep } from './views/rep/rep';

export const Pulse = () => (
  <Router>
    <SentryRoute exact path={ROUTES.ROOT}>
      <Redirect to={ROUTES.PULSE} />
    </SentryRoute>
    <SentryRoute
      exact
      path={ROUTES.LOGIN}
      render={() => <LoginHandler redirectTo={ROUTES.PULSE} />}
    />
    <SentryRoute
      path={[
        `${ROUTES.PULSE}${ROUTES.PROJECTS}/:projectId?${ROUTES.REGIONS}/:regionId?`,
        `${ROUTES.PULSE}${ROUTES.PROJECTS}/:projectId?${ROUTES.SALES_REPS}`,
        `${ROUTES.PULSE}${ROUTES.PROJECTS}/:projectId?`,
        `${ROUTES.PULSE}`,
      ]}
    >
      <PulseWrapper>
        <Navigation />
        <SentryRoute
          exact
          path={[
            `${ROUTES.PULSE}${ROUTES.PROJECTS}/:projectId?`,
            `${ROUTES.PULSE}${ROUTES.PROJECTS}/:projectId?${ROUTES.REGIONS}/:regionId?${ROUTES.SALES_REPS}`,
          ]}
        >
          <SalesReps />
        </SentryRoute>
        <SentryRoute
          exact
          path={[
            `${ROUTES.PULSE}${ROUTES.PROJECTS}/:projectId?${ROUTES.REGIONS}/:regionId?${ROUTES.SALES_REPS}/:territoryId`,
          ]}
        >
          <Rep />
        </SentryRoute>
      </PulseWrapper>
    </SentryRoute>
  </Router>
);
