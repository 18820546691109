/* eslint-disable no-restricted-syntax */
import {
  Dispatch,
  JSX,
  ReactNode,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { styled, Typography, IconButton } from "@mui/material";
import Grow from "@mui/material/Grow";
import TuneIcon from "@mui/icons-material/Tune";
import { EntityMap } from "./entityMap";
import { MapList } from "./mapList/mapList.tsx";
import EmptyView from "../emptyView";
import { ObjectiveSelector } from "../objectiveSelector";
import { AutocompleteDisplay } from "@odaia/ui/src/components/autocompleteDisplay";
import { MapRoute } from "./mapRoute";
import { useMapViewContext } from "../contexts/mapViewContext.ts";
import { Stack } from "@mui/system";
import { FilterSidebar } from "./filtering/filterSidebar.tsx";
import { MaptualList } from "@odaia/domain/src/types.ts";

const StyledMapContainer = styled("div")(() => ({
  margin: 0,
  width: "100%",
  height: "100%",
  display: "grid",
  gridTemplateRows: "min-content minmax(0, 1fr)",
  "> * a": {
    backgroundColor: "unset",
  },
}));

const RegionButtonContainer = styled("div")(() => ({
  height: 34,
  marginTop: 6,
}));

const StyledProjectAndRegion = styled("div")(
  ({ theme: { themeColors }, isVeeva }) => ({
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
    minHeight: isVeeva ? 0 : 90,
    padding: "16px 12px 12px",
    borderBottom: `1px solid ${themeColors.dividerPrimaryColor}`,
  })
);

const StyledNavigationButtonContainer = styled("div")(() => ({
  display: "flex",
  minHeight: "24px",
  alignItems: "center",
  alignSelf: "flex-end",
}));

const HCPTitle = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: "18px",
  color: themeColors.secondaryMaptualListFilteringColor,
}));

const StyledMapContentContainer = styled("div")(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "row",
  height: "100%",
}));

const StyledMap = styled("div")(() => ({
  minWidth: 500,
  height: "100%",
  marginRight: "16px",
  flexGrow: 1,
}));

const StyledMapList = styled("div")(({ isFilterOpen }) => ({
  animation: isFilterOpen ? "0.5s slideListOut" : "0.5s slideListIn",
  animationFillMode: "forwards",
  "@keyframes slideListOut": {
    from: { marginRight: 0 },
    to: { marginRight: "-400px" },
  },
  "@keyframes slideListIn": {
    from: { marginRight: "-400px" },
    to: { marginRight: 0 },
  },
}));

const StyledOptionsContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  marginLeft: "auto",
  gap: 16,
  alignItems: "flex-end",
}));

const StyledLabel = styled(Typography)(({ theme: { themeColors } }) => ({
  fontSize: 26,
  fontWeight: 600,
  maxWidth: 800,
  color: themeColors.secondaryMaptualListFilteringColor,
  marginTop: 15,
}));

const StyledSecondaryLabel = styled(Typography)(
  ({ theme: { themeColors } }) => ({
    fontSize: 16,
    fontWeight: 400,
    maxWidth: 800,
    color: themeColors.secondaryMaptualListFilteringColor,
    marginTop: 15,
  })
);

const StyledUnavailableContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: "grow",
  alignItems: "center",
  justifyContent: "center",
  align: "center",
  width: "100%",
  height: "100%",
}));

const Controls = styled("div")(({ theme: { themeColors, spacing } }) => ({
  display: "flex",
  flexDirection: "column",
  minWidth: "343px",
  padding: "12px",
  gap: "10px",
  position: "absolute",
  zIndex: 1,
  top: spacing(0.5),
  right: "26px",
  backgroundColor: themeColors.mainBackground,
  borderRadius: "4px",
  boxShadow: "0px 5px 30px 0px rgba(0, 0, 0, 0.23)",
}));

const ControlsSection = styled("div")(() => ({
  display: "flex",
  height: "42px",
  flexDirection: "row",
  gap: "8px",
}));

const StyledIconButton = styled(IconButton)(({ theme: { themeColors } }) => ({
  backgroundColor: themeColors.buttonBackgroundColor,
  color: themeColors.buttonContentColor,
  border: `1px solid ${themeColors.buttonBorderColor}`,
  borderRadius: "4px",
  boxShadow: themeColors.boxShadowLevelOne,
  "&:hover": {
    backgroundColor: themeColors.buttonBorderColor,
    borderColor: themeColors.buttonBorderColor,
  },
  padding: "8px 12px",
}));

const filterMaptualList = (
  maptualList,
  coordinates,
  selectedObjectiveIndex
) => {
  const filteredList = [];
  const { items } = maptualList.list;
  coordinates.forEach((coordinate) => {
    filteredList.push(
      items.find(
        (item) => item.snowflakeEntityId === coordinate.snowflakeEntityId
      )
    );
  });
  const sortedList = filteredList.sort((a, b) =>
    sortByPowerScore(a, b, selectedObjectiveIndex)
  );
  return sortedList;
};

const sortByPowerScore = (a, b, selectedObjectiveIndex) => {
  const aScores = a.objectiveScores || [a.maptualDisplayScore];
  const bScores = b.objectiveScores || [b.maptualDisplayScore];
  if (aScores[selectedObjectiveIndex] >= bScores[selectedObjectiveIndex]) {
    return -1;
  }

  if (aScores[selectedObjectiveIndex] < bScores[selectedObjectiveIndex]) {
    return 1;
  }

  return 0;
};
export interface Filters {
  specialtyFilter: string[];
  powerscoreFilter: {
    min: number;
    max: number;
  };
}

export type EntityPopoverProps = {
  isOpen: boolean;
  anchorEl: HTMLElement | undefined;
  setIsEntityFullModalOpen: Dispatch<SetStateAction<boolean>>;
  objective: Record<string, unknown> | null;
  entityData: MaptualList["list"]["items"][number];
  topPosition: number;
  handleClose: () => void;
  routeEntities: unknown[];
  setRoute: (entities: unknown) => void;
  setIsRouteLoading: Dispatch<SetStateAction<boolean>>;
  mapContentContainerRef: RefObject<HTMLElement>;
};

export type EntityFullModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  entityId: string;
};

type MapViewProps = {
  listMapToggle?: ReactNode;
  regionSelection?: ReactNode;
  EntityPopover?: (props: EntityPopoverProps) => JSX.Element;
  EntityFullModal?: (props: EntityFullModalProps) => JSX.Element;
  isVeeva?: boolean;
};

export const MapView = ({
  listMapToggle = null,
  regionSelection = null,
  EntityPopover,
  EntityFullModal,
  isVeeva = false,
}: MapViewProps) => {
  const {
    projectId,
    maptualList,
    isMaptualListLoading,
    isMaptualListsLoading,
    maptualListObjectives,
    entityCoordinatesData,
    isLoadingEntityCoords,
    isErrorEntityCoords,
    objective,
    setObjective,
    objectiveIndex,
    setObjectiveIndex,
    canUserFilterMapView,
  } = useMapViewContext();
  const [entityMapData, setEntityMapData] = useState<Record<string, unknown>[]>(
    []
  );
  const [filteredEntityMapData, setFilteredEntityMapData] = useState(null);

  const [visibleMaptualList, setVisibleMaptualList] = useState(
    maptualList?.list
  );
  const [filteredMaptualList, setFilteredMaptualList] = useState(
    maptualList?.list
  );
  const [visibleMaptualListLoading, setVisibleMaptualListLoading] =
    useState(false);
  const [mapCenter, setMapCenter] = useState(null);
  const [selectedMaptualListItem, setSelectedMaptualListItem] = useState(null);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [selectedGroupCoordinates, setSelectedGroupCoordinates] =
    useState(null);
  const [groupSelected, setGroupSelected] = useState(false);
  const [isEntityPopoverOpen, setIsEntityPopoverOpen] = useState(false);
  const [isEntityFullModalOpen, setIsEntityFullModalOpen] = useState(false);

  const [routeEntities, setRouteEntities] = useState<Record<string, unknown>[]>(
    []
  );
  const [routeInfo, setRouteInfo] = useState(null);
  const [isRouteLoading, setIsRouteLoading] = useState(false);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const mapContentContainerRef = useRef<HTMLDivElement>(null);

  const defaultFilters = {
    specialtyFilter: [],
    powerscoreFilter: {
      min: 0,
      max: 10,
    },
  };

  const [filters, setFilters] = useState<Filters>(defaultFilters);

  const setRoute = (entities: Record<string, unknown>[]) => {
    setRouteEntities(
      entities.map((entity) => ({
        ...entityMapData.find(
          (e) => e.snowflakeEntityId === entity.snowflakeEntityId
        ),
        ...entity,
      }))
    );
  };

  const saveFilters = (filters: Filters) => {
    sessionStorage.setItem(
      `map-view-filters-${projectId}`,
      JSON.stringify(filters)
    );
  };

  const getFilters = () => {
    const sessionFilters = sessionStorage.getItem(
      `map-view-filters-${projectId}`
    );
    if (sessionFilters) {
      return JSON.parse(sessionFilters);
    }
    return defaultFilters;
  };

  useEffect(() => {
    if (projectId) {
      const sessionFilters = getFilters();
      setFilters(sessionFilters);
    }
  }, [projectId]);

  useEffect(() => {
    if (
      !isMaptualListLoading &&
      !isLoadingEntityCoords &&
      maptualList?.list?.items?.length > 0 &&
      entityMapData?.length > 0 &&
      projectId
    ) {
      const specialityFilters = filters.specialtyFilter;
      let newFilteredMaptualList;
      let newFilteredEntityMapData;
      if (specialityFilters.length === 0) {
        newFilteredMaptualList = maptualList;
        newFilteredEntityMapData = entityMapData;
      } else {
        newFilteredMaptualList = {
          list: {
            items: maptualList?.list?.items?.filter((i) =>
              specialityFilters.includes(i.specialty.toLowerCase())
            ),
          },
        };
        newFilteredEntityMapData = entityMapData?.filter((i) =>
          specialityFilters.includes(i.specialty.toLowerCase())
        );
      }
      setFilteredMaptualList(newFilteredMaptualList);
      setFilteredEntityMapData(newFilteredEntityMapData);

      saveFilters(filters);
    }
  }, [filters, entityMapData, maptualList]);

  useEffect(() => {
    if (isMaptualListsLoading) {
      setSelectedCoordinates(null);
      setVisibleMaptualListLoading(true);
      setVisibleMaptualList(null);
    }
  }, [isMaptualListsLoading]);

  useEffect(() => {
    if (maptualListObjectives?.length > 0) {
      setObjective(maptualListObjectives[0]);
      setObjectiveIndex(0);
    }
  }, [maptualListObjectives]);

  const updateObjective = (objectiveId: string) => {
    const newObjective = maptualListObjectives.find(
      (i) => i.id === objectiveId
    );
    setVisibleMaptualListLoading(true);
    if (!newObjective) {
      return;
    }
    setObjective(newObjective);
    setObjectiveIndex(
      maptualListObjectives.findIndex((x) => x.id === objectiveId)
    );
  };

  const selectMaptualListItem = useCallback(
    (selectedEntity, zoomToEntity = true) => {
      const { items } = maptualList.list;
      const selectedIndex = items.findIndex(
        (item) => item.snowflakeEntityId === selectedEntity.snowflakeEntityId
      );
      const entityCoordinates = filteredEntityMapData.find(
        (entity) =>
          entity.snowflakeEntityId === selectedEntity.snowflakeEntityId
      );
      if (zoomToEntity) {
        setMapCenter({
          lat: entityCoordinates.coordinates?.latitude,
          lng: entityCoordinates.coordinates?.longitude,
        });
      }
      setIsFilterDrawerOpen(false);
      setIsEntityPopoverOpen(true);
      setSelectedMaptualListItem(items[selectedIndex]);
    },
    [
      maptualList.list,
      filteredEntityMapData,
      setMapCenter,
      setVisibleMaptualListLoading,
      setIsEntityPopoverOpen,
      setSelectedMaptualListItem,
      setIsFilterDrawerOpen,
    ]
  );

  useEffect(() => {
    if (
      selectedGroupCoordinates &&
      selectedMaptualListItem &&
      !selectedGroupCoordinates.find(
        (i) => i.snowflakeEntityId === selectedMaptualListItem.snowflakeEntityId
      )
    ) {
      selectGroupCoordinates([]);
    }
  }, [selectedMaptualListItem, selectedGroupCoordinates]);

  const selectCoordinates = (coordinates) => {
    setSelectedCoordinates(coordinates);
  };

  const selectGroupCoordinates = useCallback(
    (coordinates) => {
      if (coordinates.length > 0) {
        setSelectedGroupCoordinates(coordinates);
        setGroupSelected(true);
        setSelectedMaptualListItem(null);
        setIsFilterDrawerOpen(false);
      } else {
        setGroupSelected(false);
      }
    },
    [
      setSelectedGroupCoordinates,
      setGroupSelected,
      setSelectedMaptualListItem,
      setIsFilterDrawerOpen,
    ]
  );

  const clearSelectedMaptualListItem = () => {
    setSelectedMaptualListItem(null);
  };

  useEffect(() => {
    if (!selectedCoordinates) return;
    const newList = filterMaptualList(
      filteredMaptualList,
      groupSelected ? selectedGroupCoordinates : selectedCoordinates,
      objectiveIndex
    );
    setVisibleMaptualList({
      items: newList,
    });
    setVisibleMaptualListLoading(false);
  }, [
    selectedCoordinates,
    selectedGroupCoordinates,
    filteredMaptualList,
    groupSelected,
    objectiveIndex,
  ]);

  useEffect(() => {
    if (
      entityCoordinatesData &&
      entityCoordinatesData.length > 0 &&
      maptualList?.list?.items?.length > 0
    ) {
      const mapData = [];
      for (const item of maptualList.list.items) {
        const entityCoordinates = entityCoordinatesData?.find(
          (entity) => entity.entityId === item.entityId
        );
        if (!entityCoordinates) {
          // eslint-disable-next-line no-continue
          continue;
        }
        const entityMapItem = {
          snowflakeEntityId: item.snowflakeEntityId,
          score: item.maptualDisplayScore,
          objectiveScores: item.objectiveScores || [item.maptualDisplayScore],
          coordinates: {
            latitude: entityCoordinates.latitude,
            longitude: entityCoordinates.longitude,
          },
          keywords: item.keywords,
          specialty: item.specialty,
        };
        mapData.push(entityMapItem);
      }
      setEntityMapData(mapData);
    }
  }, [entityCoordinatesData, maptualList]);

  useEffect(() => {
    if (isErrorEntityCoords) setEntityMapData([]);
  }, [isErrorEntityCoords]);

  const isLoadingData = () =>
    isLoadingEntityCoords ||
    isMaptualListLoading ||
    isMaptualListsLoading ||
    !objective?.id;

  const centerMapOnEntity = (entity) => {
    setMapCenter({
      lat: entity.coordinates?.latitude,
      lng: entity.coordinates?.longitude,
    });
  };

  return (
    <>
      <StyledMapContainer>
        <StyledProjectAndRegion isVeeva={isVeeva}>
          <StyledNavigationButtonContainer>
            <HCPTitle variant="h3">HCPs</HCPTitle>
          </StyledNavigationButtonContainer>
          <StyledOptionsContainer>
            {regionSelection && (
              <RegionButtonContainer>{regionSelection}</RegionButtonContainer>
            )}
            <Stack
              direction={"column"}
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              {listMapToggle}
            </Stack>
          </StyledOptionsContainer>
        </StyledProjectAndRegion>
        <StyledMapContentContainer ref={mapContentContainerRef}>
          {isLoadingData() && !isErrorEntityCoords && <EmptyView isLoading />}
          {(isErrorEntityCoords ||
            (!isLoadingData() && !maptualList?.list?.items?.length)) && (
            <Grow in timeout={500}>
              <StyledUnavailableContainer>
                <StyledLabel>Map Unavailable</StyledLabel>
                <StyledSecondaryLabel>
                  We do not currently have a map available for the selected
                  region. Please either switch to hcp view, or select another
                  region.
                </StyledSecondaryLabel>
              </StyledUnavailableContainer>
            </Grow>
          )}
          {!isLoadingData() &&
            maptualList?.list?.items.length > 0 &&
            filteredEntityMapData && (
              <>
                <StyledMapList isFilterOpen={isFilterDrawerOpen}>
                  <MapList
                    visibleMaptualList={visibleMaptualList}
                    visibleMaptualListLoading={visibleMaptualListLoading}
                    selectedMaptualListItem={selectedMaptualListItem}
                    onSelectMaptualListItem={selectMaptualListItem}
                    groupSelected={groupSelected}
                    isPopoverOpen={isEntityPopoverOpen}
                    setIsPopoverOpen={setIsEntityPopoverOpen}
                    setIsEntityFullModalOpen={setIsEntityFullModalOpen}
                    objective={objective}
                    selectedObjectiveIndex={objectiveIndex}
                    routeEntities={routeEntities}
                    setRoute={setRoute}
                    setIsRouteLoading={setIsRouteLoading}
                    mapContentContainerRef={mapContentContainerRef}
                    EntityPopover={EntityPopover}
                  />
                </StyledMapList>
                <StyledMap>
                  <Controls>
                    <ControlsSection>
                      <AutocompleteDisplay
                        itemsForSearch={maptualList.list.items}
                        handleSelection={selectMaptualListItem}
                        optionLabel="primaryText"
                        noOptionsText="No HCPs found for that search term"
                        textFieldLabel="Find an HCP"
                        itemKey="snowflakeEntityId"
                      />
                      {canUserFilterMapView && (
                        <StyledIconButton
                          onClick={() => {
                            setIsFilterDrawerOpen(true);
                            setSelectedMaptualListItem(null);
                          }}
                        >
                          <TuneIcon />
                        </StyledIconButton>
                      )}
                    </ControlsSection>
                    <ObjectiveSelector
                      projectId={projectId}
                      updateFilterObjective={(objectiveId) =>
                        updateObjective(objectiveId)
                      }
                      objectiveId={objective?.id}
                      currentObjectives={maptualListObjectives}
                      fullWidth
                      minWidth="275px"
                    />
                  </Controls>
                  {routeEntities?.length > 0 && (
                    <MapRoute
                      setRouteEntities={setRouteEntities}
                      routeEntities={routeEntities}
                      routeInfo={routeInfo}
                      setRouteInfo={setRouteInfo}
                      isRouteLoading={isRouteLoading}
                      setIsRouteLoading={setIsRouteLoading}
                      handleRouteEntityClick={centerMapOnEntity}
                    />
                  )}
                  <EntityMap
                    setMapCenter={(center) => setMapCenter(center)}
                    mapCenter={mapCenter}
                    coordinateData={filteredEntityMapData}
                    updateBoundedCoordinates={(coordinates) => {
                      selectCoordinates(coordinates);
                    }}
                    updateIsNavigating={(newIsNavigating) =>
                      newIsNavigating
                        ? setVisibleMaptualListLoading(true)
                        : null
                    }
                    onPointClick={selectMaptualListItem}
                    onSpiderClick={selectGroupCoordinates}
                    selectedObjectiveIndex={objectiveIndex}
                    selectedEntity={selectedMaptualListItem}
                    routeEntities={routeEntities}
                    setIsPopoverOpen={setIsEntityPopoverOpen}
                    clearSelectedMaptualListItem={clearSelectedMaptualListItem}
                    setRouteInfo={(info) => setRouteInfo(info)}
                  />
                </StyledMap>
              </>
            )}
        </StyledMapContentContainer>
        {selectedMaptualListItem &&
          selectedMaptualListItem.snowflakeEntityId &&
          EntityFullModal && (
            <EntityFullModal
              open={isEntityFullModalOpen}
              setOpen={setIsEntityFullModalOpen}
              snowflakeEntityId={selectedMaptualListItem.snowflakeEntityId}
            />
          )}
      </StyledMapContainer>
      {canUserFilterMapView &&
        !isLoadingData() &&
        maptualList?.list?.items.length > 0 && (
          <FilterSidebar
            isFilterDrawerOpen={isFilterDrawerOpen}
            setIsFilterDrawerOpen={setIsFilterDrawerOpen}
            filters={filters}
            setFilters={setFilters}
          />
        )}
    </>
  );
};
